@font-face {
  font-family: 'Red Hat Display';
  src: local('Red Hat Display Regular'), local('RedHatDisplay-Regular'),
    url('./fonts/RedHatDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Red Hat Display';
  src: local('Red Hat Display It'), local('RedHatDisplay-Italic'),
    url('./fonts/RedHatDisplay-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Red Hat Display';
  src: local('Red Hat Display Medium'), local('RedHatDisplay-Medium'),
    url('./fonts/RedHatDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Red Hat Display';
  src: local('Red Hat Display Medium It'), local('RedHatDisplay-MediumItalic'),
    url('./fonts/RedHatDisplay-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Red Hat Display';
  src: local('Red Hat Display Bold'), local('RedHatDisplay-Bold'),
    url('./fonts/RedHatDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Red Hat Display';
  src: local('Red Hat Display Bold It'), local('RedHatDisplay-BoldItalic'),
    url('./fonts/RedHatDisplay-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Red Hat Display';
  src: local('Red Hat Display Black'), local('RedHatDisplay-Black'),
    url('./fonts/RedHatDisplay-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Red Hat Display';
  src: local('Red Hat Display Black It'), local('RedHatDisplay-BlackItalic'),
    url('./fonts/RedHatDisplay-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
